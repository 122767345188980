import React from 'react'

import eti_original from '../../Assets/Images/Productos falsificados/etiqueta_simbin.png'
import eti_falsa from '../../Assets/Images/Productos falsificados/Etiqueta-Simbin-RNL-60-caps_FALSO_1.png'
import orig from '../../Assets/Images/Productos falsificados/capsula_naranjabca.png'
import fal from '../../Assets/Images/Productos falsificados/capsula_blanca.png'
import amazon_logo from '../../Assets/Images/Amazon-Logo.png'
import nefropolis_logo from '../../Assets/Images/Logo_Nefropolis.png'
import nin_logo from '../../Assets/Images/Logo_NIN-02.png'

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import "swiper/css/pagination";

//MUI
import { Box, Container, Grid, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';

const ProductosFal = () => {
    return (
        <>
            <Container maxWidth='lg' sx={{ mb: 5, mt: 5 }}>
                <Typography variant='h5' textAlign='center' sx={{ fontWeight: 'bold', fontSize: '26px' }} paragraph>
                    ¡ALERTA DE PRODUCTOS FALISIFICADOS!
                </Typography>
                <Typography variant='body1' color='text.secondary' sx={{ fontSize: '18px' }} paragraph>
                    En NIN®, nos preocupamos por su bienestar y nos tomamos muy en serio la calidad de los productos que ofrecemos. Por esta
                    razón, queremos alertarle sobre la circulación de productos falsificados de nuestra marca en el mercado.
                </Typography>
                <Typography variant='h6' sx={{ fontWeight: 'bold', fontSize: '22px' }} gutterBottom>
                    ¿Cómo identificarlos?
                </Typography>
                <Typography variant='body1' color='text.secondary' sx={{ fontSize: '18px' }}>
                    A continuación encontrarás las principales características que le ayudarán a distinguir entre los productos originales y los
                    falsificados. Le pedimos que revise esta información cuidadosamente para proteger su salud y asegurarse de que está adquiriendo
                    productos auténticos.
                </Typography>
            </Container>

            <Container maxWidth='xl' sx={{ my: 5 }}>
                <Box component="section" sx={{ p: 2, mb: 5, border: '1px solid black', width: 200, background: '#0F4761', borderRadius: '4px', mx: 'auto' }}>
                    <Typography variant='body1' textAlign='center' sx={{ color: '#fff', fontWeight: 'bold' }}>ETIQUETA</Typography>
                </Box>
                <Grid container spacing={4}>
                    <Grid item xl={8} lg={8} md={7} sm={12} xs={12} sx={{ my: 'auto' }} order={{ xl: 1, lg: 1, md: 1, sm: 2, xs: 2 }}>
                        <img src={eti_original} alt='Etiqueta original' width='100%' />
                    </Grid>
                    <Grid item xl={4} lg={4} md={5} sm={12} xs={12} sx={{ my: 'auto' }} order={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                            PRODUCTO ORIGINAL
                        </Typography>
                        <ul>
                            <li><Typography variant='body1'>La ubicación del lote y caducidad se encuentra en la parte baja del envase.</Typography></li>
                            <li><Typography variant='body1'>El código de barras del producto es 7503018606323</Typography></li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container spacing={4} sx={{ mt: 3 }}>
                    <Grid item xl={8} lg={8} md={7} sm={12} xs={12} sx={{ my: 'auto' }} order={{ xl: 1, lg: 1, md: 1, sm: 2, xs: 2 }}>
                        <img src={eti_falsa} alt='Etiqueta falsa' width='100%' />
                    </Grid>
                    <Grid item xl={4} lg={4} md={5} sm={12} xs={12} sx={{ my: 'auto' }} order={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                            PRODUCTO FALSIFICADO
                        </Typography>
                        <ul>
                            <li><Typography variant='body1'>La coloración del texto es de mayor intensidad y grosor</Typography></li>
                            <li><Typography variant='body1'>La ubicación del lote y caducidad se encuentra en la etiqueta</Typography></li>
                            <li><Typography variant='body1'>El código de barras del producto no corresponde al original.</Typography></li>
                        </ul>
                    </Grid>
                </Grid>

                <Box component="section" sx={{ p: 2, mt: 8, mb: 5, border: '1px solid black', width: 200, background: '#0F4761', borderRadius: '4px', mx: 'auto' }}>
                    <Typography variant='body1' textAlign='center' sx={{ color: '#fff', fontWeight: 'bold' }}>CONTENIDO</Typography>
                </Box>
                <Grid container spacing={4}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ my: 'auto' }} order={{ xl: 1, lg: 1, md: 1, sm: 2, xs: 2 }}>
                        <img src={orig} alt='Contenido original' width='100%' />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ my: 'auto' }} order={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                            PRODUCTO ORIGINAL
                        </Typography>
                        <ul>
                            <li><Typography variant='body1'>Las cápsulas presentan una coloración naranja blanco</Typography></li>
                            <li><Typography variant='body1'>El polvo de presenta una coloración ligeramente amarilla.</Typography></li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container spacing={4} sx={{ mt: 3 }}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ my: 'auto' }} order={{ xl: 1, lg: 1, md: 1, sm: 2, xs: 2 }}>
                        <img src={fal} alt='Contenido falso' width='100%' />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ my: 'auto' }} order={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                            PRODUCTO FALSIFICADO
                        </Typography>
                        <ul>
                            <li><Typography variant='body1'>Las cápsulas son transparentes</Typography></li>
                            <li><Typography variant='body1'>El tamaño de las capsulas es más pequeña.</Typography></li>
                        </ul>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth='lg' sx={{ mb: 10 }}>
                <Typography variant='h6' sx={{ fontWeight: '600' }} paragraph>
                    Recomendaciones:
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', mb: 3 }}>
                    <Box>
                        <CheckIcon sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />
                    </Box>
                    <Typography variant='body1' color='text.secondary' sx={{ fontSize: '18px' }}>
                        Compre únicamente a través de canales oficiales: Tienda en línea y Sucursales Nefrópolis, Amazon Tiendas oficiales y Distribuidores autorizados NIN.
                    </Typography>
                </Box>
                <Box sx={{ mb: 3 }}>
                    <Swiper
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            400: {
                                slidesPerView: 1,
                            },
                            600: {
                                slidesPerView: 2,
                            },
                            830: {
                                slidesPerView: 2,
                            },
                            1000: {
                                slidesPerView: 3,
                            },
                        }}
                        spaceBetween={20}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        loop={true}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true
                        }}
                        modules={[Autoplay, Pagination]}
                        style={{ textAlign: 'center' }}
                    >
                        <SwiperSlide style={{ margin: 'auto 0 auto 0' }}>
                            <img src={amazon_logo} alt="" width='250' height='250' style={{ objectFit: 'contain' }} />
                        </SwiperSlide>

                        <SwiperSlide style={{ margin: 'auto 0 auto 0' }}>
                            <a href='https://www.nefropolis.com/tienda/' target='_blank' rel="noopener noreferrer">
                                <img src={nefropolis_logo} alt="" width='250' height='250' style={{ objectFit: 'contain' }} />
                            </a>
                        </SwiperSlide>

                        <SwiperSlide style={{ margin: 'auto 0 auto 0' }}>
                            <img src={nin_logo} alt="" width='250' height='250' style={{ objectFit: 'contain' }} />
                        </SwiperSlide>
                    </Swiper>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', mb: 3 }}>
                    <Box>
                        <CheckIcon sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />
                    </Box>
                    <Typography variant='body1' color='text.secondary' sx={{ fontSize: '18px' }}>
                        Si tiene dudas sobre un producto o distribuidor, no dude en ponerse en contacto al
                        correo <a href='mailto:servicioalcliente@nin.com.mx'>servicioalcliente@nin.com.mx</a>
                    </Typography>
                </Box>
                <Typography variant='body1' color='text.secondary' sx={{ fontSize: '18px' }}>
                    Cuidar de usted es nuestra prioridad. Si encuentra un producto sospechoso, por favor notifíquenos para que se puedan tomar
                    medidas al respecto. Gracias por su confianza y apoyo continuo.
                </Typography>
            </Container>
        </>
    )
}

export default ProductosFal