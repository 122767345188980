import React, { useEffect, useRef } from 'react'

//Router dom
import { Route, Routes, useLocation } from 'react-router-dom'

//MUI
import { createTheme, ThemeProvider } from '@mui/material'

//Components
import Index from './Components/Index/Index';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import AvisoPrivacidad from './Components/Avisos/AvisoPrivacidad/AvisoPrivacidad';
import TerminosCondiciones from './Components/Avisos/TerminosCondiciones/TerminosCondiciones';
import ProductDetailContainer from './Components/Products/ProductDetailContainer/ProductDetailContainer';
import ProductosFal from './Components/ProductosFal/ProductosFal';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1536,
    },
  },
});

function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

const App = () => {

  //Scroll to form footer
  const FormRef = useRef(null);
  const handleRef = () => {
    FormRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useScrollToTop();

  return (
    <ThemeProvider theme={theme}>
      <Header handleRef={handleRef} />
      <Routes>
        <Route path='/' element={<Index />}></Route>
        <Route path='/producto/:name' element={<ProductDetailContainer />}></Route>
        <Route path='/aviso-de-privacidad' element={<AvisoPrivacidad />}></Route>
        <Route path='/terminos-y-condiciones' element={<TerminosCondiciones />}></Route>
        <Route path='/productos-falsificados' element={<ProductosFal />}></Route>
      </Routes>
      <Footer FormRef={FormRef} />
    </ThemeProvider>
  )
}

export default App